/*  Locations Notes
- The IDs are those for the groups assigned in azure
- When a user logs in, the groups are compared against this list
- If one of the group ids matches, we use that location for the user
*/
export type LocationConfig = {
  id: string; // Azure Group ID
  name: string;
  zendesk: {
    sandbox: {
      org_id: number;
      name: string;
    };
    prod: {
      org_id: number;
      name: string;
    };
  };
};

const locations: LocationConfig[] = [
  {
    id: "42734a9f-7679-49db-bdab-f6da0e9761c8",
    name: "AZ - Oro Valley",
    zendesk: {
      sandbox: {
        org_id: 28559157707796,
        name: "AZ - Oro Valley",
      },
      prod: {
        org_id: 28560034017940,
        name: "IO: AZ - Oro Valley",
      },
    },
  },
  {
    id: "5941610c-2413-456e-98cc-e731af2f5c5c",
    name: "AZ - Tucson",
    zendesk: {
      sandbox: {
        org_id: 28559157708692,
        name: "AZ - Tucson",
      },
      prod: {
        org_id: 28560034017556,
        name: "IO: AZ - Tucson",
      },
    },
  },
  {
    id: "0624eaa3-74d1-4bc9-87b7-6445dc8fcb79",
    name: "CA - Burlingame",
    zendesk: {
      sandbox: {
        org_id: 28559157704596,
        name: "CA - Burlingame",
      },
      prod: {
        org_id: 28560034020116,
        name: "IO: CA - Burlingame",
      },
    },
  },
  {
    id: "b8e9f4cc-2259-4984-aa9d-e234c4596b98",
    name: "CA - San Diego",
    zendesk: {
      sandbox: {
        org_id: 28559157705748,
        name: "CA - San Diego",
      },
      prod: {
        org_id: 28560034018196,
        name: "IO: CA - San Diego",
      },
    },
  },
  {
    id: "d0c376a8-17a2-4f02-acba-f00af812ce65",
    name: "CA - San Francisco",
    zendesk: {
      sandbox: {
        org_id: 28559157706900,
        name: "CA - San Francisco",
      },
      prod: {
        org_id: 28560034021140,
        name: "IO: CA - San Francisco",
      },
    },
  },
  {
    id: "43aaf0be-fa58-48d9-aa1e-0839109f4281",
    name: "CA - Walnut Creek",
    zendesk: {
      sandbox: {
        org_id: 28559157704084,
        name: "CA - Walnut Creek",
      },
      prod: {
        org_id: 28560034017812,
        name: "IO: CA - Walnut Creek",
      },
    },
  },
  {
    id: "91598be0-28df-4daf-8b63-1a76854ed91b",
    name: "CO - Boulder",
    zendesk: {
      sandbox: {
        org_id: 28559157704852,
        name: "CO - Boulder",
      },
      prod: {
        org_id: 28560034021524,
        name: "IO: CO - Boulder",
      },
    },
  },
  {
    id: "96be740a-2edb-4bc8-97af-da32a56959fd",
    name: "CO - Denver (Cherry Creek)",
    zendesk: {
      sandbox: {
        org_id: 28559157706260,
        name: "CO - Denver (Cherry Creek)",
      },
      prod: {
        org_id: 28560034021396,
        name: "IO: CO - Denver (Cherry Creek)",
      },
    },
  },
  {
    id: "f5c410e6-0a22-4392-add8-215b0fd7afd1",
    name: "CO - Denver (Denver Tech Center)",
    zendesk: {
      sandbox: {
        org_id: 28559157705620,
        name: "CO - Denver (Denver Tech Center)",
      },
      prod: {
        org_id: 28560034019348,
        name: "IO: CO - Denver (Denver Tech Center)",
      },
    },
  },
  {
    id: "07cc9c8d-8d8f-437f-80a2-7d7e77e3e1f8",
    name: "CO - Lakewood",
    zendesk: {
      sandbox: {
        org_id: 28559157707412,
        name: "CO - Lakewood",
      },
      prod: {
        org_id: 28560034020244,
        name: "IO: CO - Lakewood",
      },
    },
  },
  {
    id: "53f5e39e-1317-43ed-b85c-1664f3854e2e",
    name: "CO - Westminster",
    zendesk: {
      sandbox: {
        org_id: 28559157704980,
        name: "CO - Westminster",
      },
      prod: {
        org_id: 28560034018580,
        name: "IO: CO - Westminster",
      },
    },
  },
  {
    id: "7f69b6e2-46c2-4ccb-a104-d56666097656",
    name: "DC - Washington",
    zendesk: {
      sandbox: {
        org_id: 28559157708436,
        name: "DC - Washington",
      },
      prod: {
        org_id: 28560034020756,
        name: "IO: DC - Washington",
      },
    },
  },
  {
    id: "9152107c-d5a3-43c3-9812-a1469b06cabd",
    name: "FL - Jacksonville",
    zendesk: {
      sandbox: {
        org_id: 28559157707284,
        name: "FL - Jacksonville",
      },
      prod: {
        org_id: 28560034019476,
        name: "IO: FL - Jacksonville",
      },
    },
  },
  {
    id: "10e5055b-7a55-43e2-895e-0eb9e74c037b",
    name: "FL - Palm Beach Gardens",
    zendesk: {
      sandbox: {
        org_id: 28559157708564,
        name: "FL - Palm Beach Gardens",
      },
      prod: {
        org_id: 28560034017684,
        name: "IO: FL - Palm Beach Gardens",
      },
    },
  },
  {
    id: "5a6760c9-c0f4-4b1d-bd6e-7a8a51504c15",
    name: "FL - Ponte Vedra-Nocatee",
    zendesk: {
      sandbox: {
        org_id: 28559157706644,
        name: "FL - Ponte Vedra-Nocatee",
      },
      prod: {
        org_id: 28560034021012,
        name: "IO: FL - Ponte Vedra-Nocatee",
      },
    },
  },
  {
    id: "4e5e2881-a69f-4c0f-817a-3223ec20f5f1",
    name: "GA - Atlanta",
    zendesk: {
      sandbox: {
        org_id: 28559157705108,
        name: "GA - Atlanta",
      },
      prod: {
        org_id: 28560034020884,
        name: "IO: GA - Atlanta",
      },
    },
  },
  {
    id: "c050b2d3-c5bd-4c5e-a5fd-e45d5d25e7be",
    name: "GA - Roswell",
    zendesk: {
      sandbox: {
        org_id: 28559157708308,
        name: "GA - Roswell",
      },
      prod: {
        org_id: 28560034017428,
        name: "IO: GA - Roswell",
      },
    },
  },
  {
    id: "65faf82d-2839-4c94-8398-105ce8785577",
    name: "ID - Boise",
    zendesk: {
      sandbox: {
        org_id: 28559157705492,
        name: "ID - Boise",
      },
      prod: {
        org_id: 28560034019220,
        name: "IO: ID - Boise",
      },
    },
  },
  {
    id: "6b362cb8-0638-4e1a-8b5b-cfbe1ea0b0b7",
    name: "IL - Lincolnshire",
    zendesk: {
      sandbox: {
        org_id: 28559157704212,
        name: "IL - Lincolnshire",
      },
      prod: {
        org_id: 28560034020372,
        name: "IO: IL - Lincolnshire",
      },
    },
  },
  {
    id: "a7efb211-489f-468f-b3fa-1617a0353ad0",
    name: "IL - Schaumburg",
    zendesk: {
      sandbox: {
        org_id: 28559157706388,
        name: "IL - Schaumburg",
      },
      prod: {
        org_id: 28560034018964,
        name: "IO: IL - Schaumburg",
      },
    },
  },
  {
    id: "ecc94207-394f-4eef-9eff-1ef94ff26fcf",
    name: "MA - Boston",
    zendesk: {
      sandbox: {
        org_id: 28559157708948,
        name: "MA - Boston",
      },
      prod: {
        org_id: 28560034020500,
        name: "IO: MA - Boston",
      },
    },
  },
  {
    id: "335d2ff2-7313-47d0-953d-1a8f41c53c0f",
    name: "MD - Rockville",
    zendesk: {
      sandbox: {
        org_id: 28559157704468,
        name: "MD - Rockville",
      },
      prod: {
        org_id: 28560034019860,
        name: "IO: MD - Rockville",
      },
    },
  },
  {
    id: "ea4acec9-9831-4532-9a8e-ea9124a1c008",
    name: "MI - Troy",
    zendesk: {
      sandbox: {
        org_id: 28559157705364,
        name: "MI - Troy",
      },
      prod: {
        org_id: 28560034017172,
        name: "IO: MI - Troy",
      },
    },
  },
  {
    id: "879ebb0d-28e2-42cc-b06d-f75bb09b594a",
    name: "MN - Bloomington",
    zendesk: {
      sandbox: {
        org_id: 28559157707924,
        name: "MN - Bloomington",
      },
      prod: {
        org_id: 28560034018452,
        name: "IO: MN - Bloomington",
      },
    },
  },
  {
    id: "48f880a0-f1f2-495e-ba5d-0557b4acfbd6",
    name: "NC - Raleigh",
    zendesk: {
      sandbox: {
        org_id: 28559157707668,
        name: "NC - Raleigh",
      },
      prod: {
        org_id: 28560034018068,
        name: "IO: NC - Raleigh",
      },
    },
  },
  {
    id: "53ee3f9e-89bf-4c91-8e22-37f03d5ffabd",
    name: "NJ - Marlton",
    zendesk: {
      sandbox: {
        org_id: 28559157706004,
        name: "NJ - Marlton",
      },
      prod: {
        org_id: 28560034021268,
        name: "IO: NJ - Marlton",
      },
    },
  },
  {
    id: "ad50cdc7-d472-4f14-ba58-34775f0a565d",
    name: "NJ - Princeton",
    zendesk: {
      sandbox: {
        org_id: 28559157704724,
        name: "NJ - Princeton",
      },
      prod: {
        org_id: 28560034016916,
        name: "IO: NJ - Princeton",
      },
    },
  },
  {
    id: "d2d3f9d4-0b5f-4ab7-ae88-0a85fe9408f3",
    name: "NJ - Red Bank",
    zendesk: {
      sandbox: {
        org_id: 28559157705236,
        name: "NJ - Red Bank",
      },
      prod: {
        org_id: 28560034019988,
        name: "IO: NJ - Red Bank",
      },
    },
  },
  {
    id: "51a4c8b7-9518-4fae-915c-430f39e885ec",
    name: "NV - Las Vegas",
    zendesk: {
      sandbox: {
        org_id: 28559157707540,
        name: "NV - Las Vegas",
      },
      prod: {
        org_id: 28560034017044,
        name: "IO: NV - Las Vegas",
      },
    },
  },
  {
    id: "f130b928-7e9b-45ba-89ad-8c834d7bc845",
    name: "NY - Melville",
    zendesk: {
      sandbox: {
        org_id: 28559157708052,
        name: "NY - Melville",
      },
      prod: {
        org_id: 28560034018836,
        name: "IO: NY - Melville",
      },
    },
  },
  {
    id: "0dd9d728-8090-4a85-88f3-6c613dd82a45",
    name: "NY - Uniondale",
    zendesk: {
      sandbox: {
        org_id: 28559157706772,
        name: "NY - Uniondale",
      },
      prod: {
        org_id: 28560034017300,
        name: "IO: NY - Uniondale",
      },
    },
  },
  {
    id: "ba7cb761-cd7d-4d4c-a841-e872bcbee52f",
    name: "OH - Cincinnati",
    zendesk: {
      sandbox: {
        org_id: 28559157707028,
        name: "OH - Cincinnati",
      },
      prod: {
        org_id: 28560034021652,
        name: "IO: OH - Cincinnati",
      },
    },
  },
  {
    id: "470abbab-8b1b-4d7a-980f-49d47e821c88",
    name: "OH - Columbus",
    zendesk: {
      sandbox: {
        org_id: 28559157706516,
        name: "OH - Columbus",
      },
      prod: {
        org_id: 28560034020628,
        name: "IO: OH - Columbus",
      },
    },
  },
  {
    id: "ce010f3c-92be-4647-bd40-e6d72a0335a0",
    name: "PA - Philadelphia",
    zendesk: {
      sandbox: {
        org_id: 28559157706132,
        name: "PA - Philadelphia",
      },
      prod: {
        org_id: 28560034018708,
        name: "IO: PA - Philadelphia",
      },
    },
  },
  {
    id: "fbca0100-8b09-493e-a708-e8c5dcc31ab7",
    name: "TN - Nashville",
    zendesk: {
      sandbox: {
        org_id: 28559157708820,
        name: "TN - Nashville",
      },
      prod: {
        org_id: 28560034019604,
        name: "IO: TN - Nashville",
      },
    },
  },
  {
    id: "e9838322-eace-4c07-9655-47de50fa2c30",
    name: "TX - Dallas",
    zendesk: {
      sandbox: {
        org_id: 28559157708180,
        name: "TX - Dallas",
      },
      prod: {
        org_id: 28560034019092,
        name: "IO: TX - Dallas",
      },
    },
  },
  {
    id: "a20f2dfb-8088-409b-b997-b16b322b16c4",
    name: "TX - El Paso East",
    zendesk: {
      sandbox: {
        org_id: 28559157704340,
        name: "TX - El Paso East",
      },
      prod: {
        org_id: 28560034018324,
        name: "IO: TX - El Paso East",
      },
    },
  },
  {
    id: "745fe2e1-fc22-4817-9f7c-1266be40c426",
    name: "TX - El Paso West",
    zendesk: {
      sandbox: {
        org_id: 28559157705876,
        name: "TX - El Paso West",
      },
      prod: {
        org_id: 28560034019732,
        name: "IO: TX - El Paso West",
      },
    },
  },
  {
    id: "c4c88b0d-e211-4d17-891d-150cc0996d8f",
    name: "VA - Alexandria",
    zendesk: {
      sandbox: {
        org_id: 28559157707156,
        name: "VA - Alexandria",
      },
      prod: {
        org_id: 28560034023572,
        name: "IO: VA - Alexandria",
      },
    },
  },
  {
    id: "cbe358d4-0aa4-46c6-b907-736cc10ea05b",
    name: "VA - Arlington",
    zendesk: {
      sandbox: {
        org_id: 28559157710612,
        name: "VA - Arlington",
      },
      prod: {
        org_id: 28560034021908,
        name: "IO: VA - Arlington",
      },
    },
  },
  {
    id: "9843a9e6-f82a-4bed-8161-86d17d4f3a87",
    name: "VA - Fairfax",
    zendesk: {
      sandbox: {
        org_id: 28559157709460,
        name: "VA - Fairfax",
      },
      prod: {
        org_id: 28560034023828,
        name: "IO: VA - Fairfax",
      },
    },
  },
  {
    id: "1f027820-1758-44ac-88f3-2b0e065d1d4b",
    name: "VA - Reston",
    zendesk: {
      sandbox: {
        org_id: 28559157709588,
        name: "VA - Reston",
      },
      prod: {
        org_id: 28560034023444,
        name: "IO: VA - Reston",
      },
    },
  },
  {
    id: "92750ba6-d0c9-4a55-a4f1-565869a85069",
    name: "VA - Tysons Corner",
    zendesk: {
      sandbox: {
        org_id: 28559157709844,
        name: "VA - Tysons Corner",
      },
      prod: {
        org_id: 28560034021780,
        name: "IO: VA - Tysons Corner",
      },
    },
  },
  {
    id: "7da71270-2db7-46b5-86ad-c4e4973bfb54",
    name: "AB - Edmonton",
    zendesk: {
      sandbox: {
        org_id: 28559157709332,
        name: "AB - Edmonton",
      },
      prod: {
        org_id: 28560034022548,
        name: "IO: AB - Edmonton",
      },
    },
  },
  {
    id: "9ff930a2-adcb-4346-93aa-fb9cbd88890c",
    name: "BC - Vancouver (West Broadway)",
    zendesk: {
      sandbox: {
        org_id: 28559157710100,
        name: "BC - Vancouver (West Broadway)",
      },
      prod: {
        org_id: 28560034022164,
        name: "IO: BC - Vancouver (West Broadway)",
      },
    },
  },
  {
    id: "ead44555-db7a-4353-8955-fbeb09d44e60",
    name: "ON - Hamilton",
    zendesk: {
      sandbox: {
        org_id: 28559157709204,
        name: "ON - Hamilton",
      },
      prod: {
        org_id: 28560034022420,
        name: "IO: ON - Hamilton",
      },
    },
  },
  {
    id: "b76f6df0-6b73-4e51-b9e9-67be98dfe524",
    name: "ON - Mississauga (City Centre)",
    zendesk: {
      sandbox: {
        org_id: 28559157709972,
        name: "ON - Mississauga (City Centre)",
      },
      prod: {
        org_id: 28560034023700,
        name: "IO: ON - Mississauga (City Centre)",
      },
    },
  },
  {
    id: "8ffa9a95-2801-4d49-b638-cea37851513b",
    name: "ON - Mississauga (Matheson Blvd)",
    zendesk: {
      sandbox: {
        org_id: 28559157710484,
        name: "ON - Mississauga (Matheson Blvd)",
      },
      prod: {
        org_id: 28560034022036,
        name: "IO: ON - Mississauga (Matheson Blvd)",
      },
    },
  },
  {
    id: "23524a13-b941-41be-9b07-03d67f3aae70",
    name: "ON - Oakville (Halton)",
    zendesk: {
      sandbox: {
        org_id: 28559157709716,
        name: "ON - Oakville (Halton)",
      },
      prod: {
        org_id: 28560034022804,
        name: "IO: ON - Oakville (Halton)",
      },
    },
  },
  {
    id: "1ad0bb0b-2534-48c2-b61e-354b0bf28186",
    name: "ON - Scarborough",
    zendesk: {
      sandbox: {
        org_id: 28559157710228,
        name: "ON - Scarborough",
      },
      prod: {
        org_id: 28560034023060,
        name: "IO: ON - Scarborough",
      },
    },
  },
  {
    id: "72997dbc-781f-497d-8122-0143279e3ea7",
    name: "ON - Toronto (North York)",
    zendesk: {
      sandbox: {
        org_id: 28559157710996,
        name: "ON - Toronto (North York)",
      },
      prod: {
        org_id: 28560034022932,
        name: "IO: ON - Toronto (North York)",
      },
    },
  },
  {
    id: "4e327b4f-441b-41e4-bc0e-d927507f9ad3",
    name: "ON - Toronto First Canadian Place",
    zendesk: {
      sandbox: {
        org_id: 28559157710740,
        name: "ON - Toronto First Canadian Place",
      },
      prod: {
        org_id: 28560034022292,
        name: "IO: ON - Toronto First Canadian Place",
      },
    },
  },
  {
    id: "900fdae6-1579-4ccc-b835-355f6cc5dc7b",
    name: "ON - Toronto Hudson's Bay Centre",
    zendesk: {
      sandbox: {
        org_id: 28559157710868,
        name: "ON - Toronto Hudson's Bay Centre",
      },
      prod: {
        org_id: 28560034022676,
        name: "IO: ON - Toronto Hudson's Bay Centre",
      },
    },
  },
  {
    id: "e65624e8-3f85-4cdd-93d7-810dc80d0103",
    name: "ON - Toronto Yonge Eglinton Centre",
    zendesk: {
      sandbox: {
        org_id: 28559157710356,
        name: "ON - Toronto Yonge Eglinton Centre",
      },
      prod: {
        org_id: 28560034023316,
        name: "IO: ON - Toronto Yonge Eglinton Centre",
      },
    },
  },
  {
    id: "f0537711-7eb2-4abc-a26a-74df4ea6dcce",
    name: "ON - Vaughan",
    zendesk: {
      sandbox: {
        org_id: 28559157709076,
        name: "ON - Vaughan",
      },
      prod: {
        org_id: 28560034023188,
        name: "IO: ON - Vaughan",
      },
    },
  },
];

export default locations;

export const cadAbbrs = ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT"];
export const usaAbbrs = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
